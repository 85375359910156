import MGArora from'./images/MGArora.png'; 
import Dilbagh from'./images/Dilbaghsingh.png';
import AshokTayal from'./images/MAAC-Partners-Ashok_Tayal.png';
import SanjulaGupta from'./images/MAAC-Partners-sanjula_gupta.png';
import Yashpalsood from'./images/MAAC-Partners-yashpal_sood.png';

const Tdata = [
    {
       img:MGArora,
        Name:"CA Madan Arora",
       Des:"Founder & Partner",
       Exp:"Experience 40 years",
       Spe:"Expertise: Accounting & Tax"
    },
    {
        img:AshokTayal,
        Name:"Ashok Tayal",
        Des:" Partner",
        Exp:"Experience 40 years",
        Spe:"Expertise: Accounting & Tax"
     },
     {
        img:Yashpalsood,
        Name:"Yashpal Sood",
        Des:"Partner",
        Exp:"Experience 40 years",
        Spe:"Expertise: Accounting & Tax"
     },
     {
        img:SanjulaGupta,
        Name:"Sanjula Gupta",
        Des:" Partner",
        Exp:"Experience 40 years",
        Spe:"Expertise: Accounting & Tax"
     },
     {
        img:Dilbagh,
        Name:"CA Dilbagh Singh",
        Des:"Partner",
        Exp:"Experience 40 years",
        Spe:"Expertise: Accounting & Tax"
     },
]

export default Tdata;