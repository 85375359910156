import React from "react";
import Teamcard from "../Teamcard";
import Footer from "../Footer";
import Header from '../Header';
import Navbar from '../Navbar';
import Tdata from "../TeamData";
import ScrollToTop from "../ScrollToTop";


const Team = () => {
    return(
    <>
    <Header />
    <Navbar/>
    <div>
     <div class='Team-header'>
    <div>
      <h1 className='team-hero-heading'>Team</h1>
      
    </div>
</div>
</div>
<div class='team-align'>
<Teamcard img={Tdata[0].img} Name={Tdata[0].Name} Des={Tdata[0].Des} Exp={Tdata[0].Exp} Spe={Tdata[0].Spe}/>
<Teamcard img={Tdata[1].img} Name={Tdata[1].Name} Des={Tdata[1].Des} Exp={Tdata[1].Exp} Spe={Tdata[1].Spe}/>
<Teamcard img={Tdata[2].img} Name={Tdata[2].Name} Des={Tdata[2].Des} Exp={Tdata[2].Exp} Spe={Tdata[2].Spe}/>
</div>
<div class='team-align'>
<Teamcard img={Tdata[3].img} Name={Tdata[3].Name} Des={Tdata[3].Des} Exp={Tdata[3].Exp} Spe={Tdata[3].Spe}/>
<Teamcard img={Tdata[4].img} Name={Tdata[4].Name} Des={Tdata[4].Des} Exp={Tdata[4].Exp} Spe={Tdata[4].Spe}/>
</div><br></br><br></br><br></br>
<ScrollToTop />

<Footer/>


    </>
    );
}

export default Team ;