import React from 'react'
import blog_1 from './Blog-1.webp';


const Blogs = () => {
    return(
        <>
        <div class='blog-align'>
    <img src={blog_1} alt='Blog-1' class='blog-image'/>
    <p class='blog-category'>Category :<span> GST</span></p>
    <h1 class='blog-heading'>How to Hire Chartered Accountant ?</h1>
    <p class='blog-content'>At Mohindro Arora & Co., our strength lies in our team of highly skilled and dedicated professionals who bring a wealth of knowledge and experience to every client engagement. Each team member is committed to delivering exceptional service and tailored solutions that meet the unique needs of our clients. We invite you to meet the experts who drive our success and uphold our values of integrity, professionalism, and excellence</p>
    
    <div class='blog-date-section'>
    <p class='blog-readmore'>Continue Reading</p>
    <p class='blog-date'>June, 06 2024</p>
    </div>

    </div>
        </>
    );
}


export default Blogs ;