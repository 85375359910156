import React from 'react';
import '../style.css';
import Header from '../Header';
import Footer from '../Footer';
import Navbar from '../Navbar';
import ScrollToTop from "../ScrollToTop";


function Services() {
    return(
        <>
        <Header/>
        
        <Navbar/>
    <div class='service-header'>
    <div>
      <h1 class='service-heading'>INCOME TAX PLANNING AND COMPLIANCE</h1>
      <p class='service-home-link'>Home  Services</p>
    </div>
   </div>
 
   <div class='service-content'>
<div>
  <h1 class='service-income'>Income Tax Planning and compliance </h1>
  <p class='service-para'>Navigating the complexities of income tax regulations can be daunting. At Mohindro Arora & Co., we provide comprehensive income tax planning and compliance services designed to optimize your tax liabilities and ensure full compliance with the latest laws and regulations.</p>

  <h1 class='service-income'>Income Tax Planning and compliance </h1>
  <p class='service-para'>Navigating the complexities of income tax regulations can be daunting. At Mohindro Arora & Co., we provide comprehensive income tax planning and compliance services designed to optimize your tax liabilities and ensure full compliance with the latest laws and regulations.</p>

  <h1 class='service-income'>Income Tax Planning and compliance </h1>
  <p class='service-para'>Navigating the complexities of income tax regulations can be daunting. At Mohindro Arora & Co., we provide comprehensive income tax planning and compliance services designed to optimize your tax liabilities and ensure full compliance with the latest laws and regulations.</p>


  <h1 class='service-income'>Income Tax Planning and compliance </h1>
  <p class='service-para'>Navigating the complexities of income tax regulations can be daunting. At Mohindro Arora & Co., we provide comprehensive income tax planning and compliance services designed to optimize your tax liabilities and ensure full compliance with the latest laws and regulations.</p>

  <h1 class='service-income'>Income Tax Planning and compliance </h1>
  <p class='service-para'>Navigating the complexities of income tax regulations can be daunting. At Mohindro Arora & Co., we provide comprehensive income tax planning and compliance services designed to optimize your tax liabilities and ensure full compliance with the latest laws and regulations.</p>

  <h1 class='service-income'>Income Tax Planning and compliance </h1>
  <p class='service-para'>Navigating the complexities of income tax regulations can be daunting. At Mohindro Arora & Co., we provide comprehensive income tax planning and compliance services designed to optimize your tax liabilities and ensure full compliance with the latest laws and regulations.</p>

  <h1 class='service-income'>Income Tax Planning and compliance </h1>
  <p class='service-para'>Navigating the complexities of income tax regulations can be daunting. At Mohindro Arora & Co., we provide comprehensive income tax planning and compliance services designed to optimize your tax liabilities and ensure full compliance with the latest laws and regulations.</p>

  <h1 class='service-income'>Income Tax Planning and compliance </h1>
  <p class='service-para'>Navigating the complexities of income tax regulations can be daunting. At Mohindro Arora & Co., we provide comprehensive income tax planning and compliance services designed to optimize your tax liabilities and ensure full compliance with the latest laws and regulations.</p>
</div>

</div>

{/* /*Subscribe section*/} 

<div class='subscribe-section'>
 <h1>Subscribe to our Newsletter</h1>
 <div class='email-portion'>
 <h2>email</h2>
 <input type='email' placeholder='enter your email' class='subscribe-email-box' /></div>
 <button>Subscribe</button>

</div>
<ScrollToTop />
<Footer/>

</>

    );
}

export default Services ;