// src/ScrollToTop.js
import React, { useEffect, useState } from 'react';
import './style.css';
import Toparrow from './images/up-arrow.svg';
import whatsappicon from './images/WhatsApp_icon.png';



const ScrollToTop = () => {

  const phoneNumber = "+919310039247"; 
  const message = "Hello, I would like to book a demo.";
  const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
 

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div className="scroll-to-top">
      {isVisible && (
        <> 
        <div className='scroll-button-alignment'>
        <a href={url} target='_blank' rel="noopener noreferrer"><button className='whatsapp-button'>

        <img src={whatsappicon} alt='Whatsapp'/>
        </button></a>
        
        <button onClick={scrollToTop} className="scroll-button">
          <img  src={Toparrow} alt='TOP' className='top-arrow-style'/>
        </button>
        </div>
        </>
      )}
    </div>
  );
};

export default ScrollToTop;
 
