import React from 'react'
import companyLogo from "./Group 35.png";
import {Link} from 'react-router-dom';
import './style.css';


const Navbar = () => {
    return(
        <>
<div class='Logoportion'>
   <img src={companyLogo} alt='Logo' Class='logo'/>
   <nav class="navbar" >
     <ul class='navbar-list'>
      <li><Link to="/" class='link'>Home</Link></li>
      <li><Link to="/about" class='link'>About us</Link></li>
      {/* <li class='navli'><Link to="/services" class='link'>Services</Link></li> */}
      <li><Link to="/client" class='link'>Clients</Link></li>
      <li><Link to="/team" class='link'>Team</Link></li>
      {/* <li><Link to="/blog" class='link'>Blog</Link></li> */}
      <li><Link to="/contactus" class='link'>Contact us</Link></li>
     </ul>
    </nav>
   </div>
        </>
    );
}

export default Navbar ;