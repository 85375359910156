import React from 'react'
import '../style.css'
import about1 from '../about-1.png';
import Header from '../Header';
import about2 from '../about-2.png';
import about3 from '../about-3.png';
import Footer from '../Footer';
import Navbar from '../Navbar';
import ScrollToTop from "../ScrollToTop";




function About() {
    return(
        <>
   <Header />
   <Navbar/>
    <div class='about-header'>
    <div>
      <h1 class='about-heading'>About us</h1>
      
    </div>
</div>

<div class='about-1-sec'>
  
  <div>
    <h1 class='about1-head'> About us</h1>
    <p class='about1-para'>Welcome to Mohindra Arora & Co., a premier chartered accountancy firm based in New Delhi, India, with a branch in Mumbai. Established with the vision of providing top-notch financial and advisory services, we have built a reputation for excellence and integrity. With over 39 years of experience, our firm has become a trusted partner for businesses and individuals alike.</p>
  </div>
    <img src={about1} alt='aboutus' class='about-1-img'/>
</div>

<div class='about-2-sec'>
  
    <img src={about2} alt='aboutus' class='about-1-img'/>
    <div>
    <h1 class='about2-head'> Our History</h1>
    <p class='about2-para'>Founded by CA U.K. Mohindra and CA M.G. Arora,  Our firm has been committed to delivering personalized services tailored to meet the unique needs of each client. Our journey began with a small team of dedicated professionals and has since expanded to include a robust team of experienced chartered accountants and financial experts.</p>
  </div>
</div>

<div class='about-1-sec'>
  
  <div>
    <h1 class='about1-head'> Our Expertise</h1>
    <p class='about1-para'>At Mohindro Arora & Co., we offer a comprehensive range of services including:</p>
    <ul class='about-3-list'>
      <li>Income Tax Planning and Compliance</li>
      <li>Goods and Services Tax (GST) Advisory</li>
      <li>Auditing and Assurance</li>
      <li>Corporate Law and Secretarial Services</li>
      <li>NRI Taxation</li>
      <li>Startup Services</li>
      <li>Merger and Acquisitions</li>
      <li>Software Analysis</li>
      <li>Financial Planning and Advisory</li>
    </ul>
  </div>
    <img src={about3} alt='aboutus' class='about-1-img'/>
</div>

<div class='result-section'>

    <div>
    <p class='result-no'>1000+ </p>
    <p class='result-heading'>Clients</p>
    </div>
   
    <div>
    <p class='result-no'>25+ </p>
    <p class='result-heading'>Consultants</p>
    </div>

    {/* <div>
    <p class='result-no'>5</p>
    <p class='result-heading'>Award winning</p>
    </div> */}

    <div>
    <p class='result-no'> 40+ </p>
    <p class='result-heading'>Years of experience</p>
    </div>

  </div>

  <div class='values-section'>
    
    <h1 class='value-head'>Our Values </h1>
    <p class='value-para'>Integrity, professionalism, and client-centricity are the cornerstones of our practice. We believe in building lasting relationships based on trust and transparency. Our approach is driven by a deep understanding of our clients’ goals, allowing us to deliver customized solutions that drive growth and success</p>
   
  </div>

  <ScrollToTop />
  <Footer />
</>
    );
}

export default About ;