import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './style.css';
import { useNavigate } from 'react-router-dom';


const QueryForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phonenumber:'',
    email: '',
    message: '',
  });

  const [status, setStatus] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    

    emailjs.send(
      'service_10nr0ja',
      'template_x83or8c',
      {
        ...formData,
        to_email: 'mohindraaroraandco@gmail.com', // Ensure this matches the email address in your EmailJS template
      },
      'vMshMOkCp6pBCW3Cv'
    )
    .then((response) => {
        console.log(response)
      setStatus('SUCCESS');
      setFormData({
      
        name: '',
        phonenumber:'',
        email: '',
        message: '',
      });
    })
    .catch((error) => {
      setStatus('FAILED');
      console.error('Email sending error:', error);
    });
    
  };

  return (
    <div className="query-form-container">
      <p class='message'>Send  a message</p>
    <h1 class='get-heading'>Get in Touch</h1>
    <p class='get-context'>We will respond to your message as soon as possible </p>
      <form className="query-form" onSubmit={handleSubmit}>
      {/* <div className="form-group">
          <label htmlFor="name"> Hotel Name</label>
          <input
            type="text"
            id="hotelname"
            name="hotelname"
            value={formData.hotelname}
            onChange={handleChange}
            required
          />
        </div> */}
        <div className="form-group">
          <label htmlFor="name"> Your Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="name">Your Phone No.</label>
          <input
            type="text"
            id="phonenumber"
            name="phonenumber"
            value={formData.phonenumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Your Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit">Submit</button>
        {status === 'SUCCESS' && navigate('/thankyou')}
        {status === 'FAILED' && <p className="error-message">Sorry, something went wrong. Please try again.</p>}
      </form>
    </div>
  );
};

export default QueryForm;
