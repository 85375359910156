 
 const Clientdata = [
{
    industry:"IT & Software",
    sub1:"Software Development",
    sub2:"IT Consulting",
    sub3:"Cybersecurity"
},
{
    industry:" Financial Services",
    sub1:"Banking",
    sub2:"Investment Advisory",
    sub3:"Insurance"
},
{
    industry:"Manufacturing & Engineering",
    sub1:"Automobile Manufacturing",
    sub2:"Heavy Machinery",
    sub3:"Consumer Goods"
},
{
    industry:"Healthcare & Pharmaceuticals",
    sub1:"Hospitals",
    sub2:"Pharmaceuticals",
    sub3:"Medical Devices"
},
{
    industry:" Real Estate & Construction",
    sub1:"Residential Development",
    sub2:"Commercial Construction",
    sub3:"Green Building"
},
{
    industry:"Education & Training",
    sub1:"Higher Education",
    sub2:"Professional Training",
    sub3:"K-12 Schools"
},
{
    industry:"Retail & Consumer Goods",
    sub1:"Supermarkets",
    sub2:"Fashion Retail",
    sub3:"Home Goods"
},
{
    industry:"Hospitality & Tourism",
    sub1:"Hotels",
    sub2:"Travel Agencies",
    sub3:"Resorts"
},
{
    industry:"Media & Entertainment",
    sub1:"Film Production",
    sub2:"Television",
    sub3:"Event Management"
},
{
    industry:"Telecommunications",
    sub1:"Network Providers",
    sub2:"Telecom Equipment",
    sub3:"Internet Services"
},
{
    industry:"Energy & Utilities",
    sub1:"Renewable Energy",
    sub2:"Electric Utilities",
    sub3:"Water Utilities"
},{
    industry:"Automotive",
    sub1:"Vehicle Manufacturing",
    sub2:"Auto Parts",
    sub3:"Dealerships"
},{
    industry:"Non-Profit & NGOs",
    sub1:"Healthcare",
    sub2:"Environmental Conservation",
    sub3:"Child Welfare"
},{
    industry:"Food & Beverage",
    sub1:"Restaurants",
    sub2:"Beverage Companies",
    sub3:"Food Manufacturing"
},


 ]

 export default Clientdata;