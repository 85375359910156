import React from "react";
import './style.css' ;


function Teamcard(props) {
    return(
    <>
      <div class='team-card'>
      <div class='card-bar'></div>
      <img src={props.img} alt="Partners-img" class='team-img'/>
      <h2 class='team-name'>{props.Name}</h2>
      <h2 class='team-des'>{props.Des}</h2>
      {/* <p class='team-exp'>{props.Exp}</p> */}
      {/* <p class='team-ese'>{props.Spe}</p> */}
           
      </div>               
    </>

    );

}

export default Teamcard ;