import React from "react";
import './style.css';
import { FaFacebook,FaLinkedin,FaInstagram } from "react-icons/fa";
import {  BsTwitterX } from "react-icons/bs";
import { MdOutlineMail , MdCall} from "react-icons/md" ;
import { MdLocationOn } from "react-icons/md";
import companyLogo from "./Group 35.png";
import {Link} from 'react-router-dom';




function Footer() {
    return(
<>
<footer class='footer-portion'>
   

   <div className="footer-upper">

   <div className="footer-logo">
   <img src={companyLogo} alt='Logo' Class='logo'/>
   <p>Your Trusted Partner in Financial Solutions</p>
   {/* <p>Mohindro Arora and Co. is a renowned chartered accountancy firm located in New Delhi. With a legacy of excellence and a commitment to integrity</p> */}
   </div>

 
   <div className="footer-link">
    <h1>Useful Links</h1>
    <ul class='useful-link'>
      <li><Link to="/" className="footers-link" >Home</Link></li>
      <li><Link to="/about" className="footers-link" >About Us</Link></li>
      {/* <li>Services</li> */}
      <li><Link to="/client" className="footers-link" >Clients</Link></li>
      <li><Link to="/team" className="footers-link" >Team</Link></li>
      {/* <li>Blog</li> */}
      <li><Link to="/contactus" className="footers-link" >Contact Us</Link></li>   
    </ul>
   </div>

   {/* <div>
    <h1>Our Services</h1>
    <ul class='useful-link'>
      <li>Accounting</li>
      <li>Auditing and Assurance</li>
      <li>Income Tax</li>
      <li>Startup Services</li>
      <li>Gst</li>
      <li>Merger and Acquisitions</li>
      <li>Finance</li>   
    </ul>
   </div> */}

   {/* <div className="footer-link">
    <h1>Important Links</h1>
    <ul class='useful-link'>
      <li>Income Tax Department</li>
      <li>Ministry of Corporate Affairs</li>
      <li>Employees Provident Fund</li>     
    </ul>
   </div> */}
  

   <div className="footer-link">
    <h1>Contact Us</h1>
    <ul class='useful-link'>
  <div>
    <MdLocationOn class='headericons'  style={{height:'30px',width:'30px', marginTop:'2px'}} />
    
  
    <li className='address'>Bunglow Plot No. 2, First Floor West Patel Nagar, Opp. Metro Pillar No.-229 New Delhi - 110008</li>
      </div>
      <div><MdCall class='headericons'  style={{height:'28px',width:'28px'}}/><li>+91 9310039247</li></div>
      <div><MdOutlineMail class='headericons'  style={{height:'28px',width:'28px'}}/> <li>maco4531@hotmail.com</li>  </div>   
    </ul>
   </div>

   </div>

   <div className="footer-lower">

   <div class='copyright-section'>
   <p> &copy; 2024 Mohindra Arora & Co. All Rights Reserved.</p>
   </div>

   <div className="footer-social">
  <p style={{color:'white'}}>Follow us :</p>
  <a href="https://www.facebook.com/mohindraaroraandco/" target="_blank"><FaFacebook class='footericons'/></a>
  <a href="https://x.com/Mohindraaroraco" target="_blank"><BsTwitterX class='footericons'/></a>
  <a href="https://www.linkedin.com/company/mohindraaroraandco/" target="_blank"><FaLinkedin class='footericons'/></a>
  <a href="https://www.instagram.com/mohindraaroraandco/" target="_blank"><FaInstagram class='footericons'/></a>
  </div>

  </div>  



  </footer>
</>

    );
}

export default Footer ;