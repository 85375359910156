import React from "react";
import Home from "./pages/Home";
import About from './pages/About';
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import Team from "./pages/Team";
import Client from "./pages/Client";
import Blogpage from "./pages/Blogpage";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import Thankyoupage from "./Thankyoupage.js";


// import 'bootstrap/dist/css/bootstrap.min.js';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';


import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";



function App() {
    return(
<>
<Router >
  <Routes>
    <Route   path='/' element={<Home />} />  
    <Route   path='/about' element={<About />} />   
    <Route   path='/contactus' element={<Contact/>} />
    <Route  path ='/services' element={<Services/>} />
    <Route  path ='/team' element={<Team/>} />
    <Route  path ='/client' element={<Client/>} />
    <Route  path ='/blog' element={<Blogpage/>} />
    <Route path="/thankyou" element={<Thankyoupage />} />
  </Routes>
</Router>

</>
    );
}

export default App;