import React from 'react';
import './style.css';

function Clientcard(props) {
    return(
        <>
         <div class='client-card'>
           <h1 class='client-industry'>{props.industry}</h1>
           <ul class='client-industry-subcategory'>
            <li>{props.sub1}</li>
            <li>{props.sub2}</li>
            <li>{props.sub3}</li>
           </ul>
         </div>
        </>
    );
}

export default Clientcard ;