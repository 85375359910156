import React from "react";
import './style.css';
import branding from './branding.jpg';

function Blog() {
    return(
        <>
        <div class='recent-blog-card'>
        <img src={branding} alt='values' class='blog-post-img'/>
        <p class='blog-text'>To be the most respected and trusted professional organization devoted to delivering excellence.</p>
        <p class='blog-readmore'>Readmore</p>

        </div>
        </>
    );
}

export default Blog ;