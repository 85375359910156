import React from 'react'
import Header from '../Header';
import Footer from '../Footer';
import Navbar from '../Navbar';
import { MdOutlineMail , MdCall} from "react-icons/md" ;
import { MdLocationOn } from "react-icons/md";
import QueryForm from '../Queryform';
import ScrollToTop from "../ScrollToTop";


function Contact() {
    return(
      
        <>
       
        <Header />
   <Navbar/>
        <div class='contact-hero'>
        
          <h1 class='Contact-heading'>Contact Us</h1>
       
        </div>
    <div class='contact-row-1'>
     <div class='contact-infocard'>
     
      <h1>Contact Information</h1>
      {/* <div class='contact-line'></div> */}
      <ul class='useful-link'>
  <div>
    <MdLocationOn class='headericons'  style={{height:'30px',width:'30px', marginTop:'2px'}} />
    
  
    <li className='address'>Bunglow Plot No. 2, First Floor West Patel Nagar, Opp. Metro Pillar No.-229 New Delhi - 110008</li>
      </div>
      <div><MdCall class='headericons'  style={{height:'28px',width:'28px'}}/><li>+91 9310039247</li></div>
      <div><MdOutlineMail class='headericons'  style={{height:'28px',width:'28px'}}/> <li>maco4531@hotmail.com</li>  </div>   
    </ul>

     </div>

    <QueryForm/>

   </div>
   
   <div class='contact-map'>
    <h1>Find us on Google Map</h1>
     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.302631765129!2d77.15758607457353!3d28.650655883259166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d036fccf90297%3A0x1c262c20e33091b8!2sMOHINDRA%20ARORA%20AND%20CO!5e0!3m2!1sen!2sin!4v1718265048597!5m2!1sen!2sin" width="1100" height="350"  allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='MohindraMap'></iframe>
     </div>
     <ScrollToTop />
<Footer/>
      </>
    );
}

export default Contact ;