import React from "react";
import './style.css';
import { MdOutlineMail , MdCall} from "react-icons/md" ;
import { PiLineVerticalLight } from "react-icons/pi";
import { FaFacebook,FaLinkedin,FaInstagram } from "react-icons/fa";
import {  BsTwitterX } from "react-icons/bs";


const Header =() => {
    return(
        <>
<div class='header '>
  
  <div class='left'>
  <MdOutlineMail class='headericons'/>
  <a href="mailto:maco4531@hotmail.com?subject=Inquiry&body=Hello, I have a question about your services."><p>maco4531@hotmail.com</p> </a>
  <MdCall class='headericons'/>
  <a href="tel:+91 9310039247"><p>+91 9310039247</p></a>
  </div>

  <div class='right'>
  <p>Connect with us</p>
  <PiLineVerticalLight class='line' />
  <a href="https://www.facebook.com/mohindraaroraandco/" target="_blank"><FaFacebook class='headericons'/></a>
  <a href="https://x.com/Mohindraaroraco" target="_blank"><BsTwitterX class='headericons'/></a>
  <a href="https://www.linkedin.com/company/mohindraaroraandco/" target="_blank"><FaLinkedin class='headericons'/></a>
  <a href="https://www.instagram.com/mohindraaroraandco/" target="_blank"><FaInstagram class='headericons'/></a>
  </div>
</div>
        </>
    )
}


export default Header ;