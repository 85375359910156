import React from "react";
import Clientcard from "../Clientcard";
import '../style.css';
import Footer from "../Footer";
import Navbar from '../Navbar';
import Header from '../Header';
import Clientdata from "../ClientIndustrydata";
import ScrollToTop from "../ScrollToTop";



const Client =() => {
    return(
      
        <>
        <Header />
        <Navbar />
        


  <div class='client-hero'>
    <div>
      <h1 class='client-heading'>Clients</h1>
      {/* <p class='service-home-link'><span >Home</span>  Client</p> */}
    </div>
   
  </div>

  <div class='values-section'>
  
    <h1 class='value-head'>Our Clients </h1>
    <p class='value-para'>Integrity, professionalism, and client-centricity are the cornerstones of our practice. We believe in building lasting relationships based on trust and transparency. Our approach is driven by a deep understanding of our clients’ goals, allowing us to deliver customized solutions that drive growth and success</p>
    <p class='client-highlight-para'>Here is a glimpse of our valued clients industry: </p>
  </div>

<div className="client-industries">
<div class='client-card-align'>
<Clientcard industry={Clientdata[0].industry} sub1={Clientdata[0].sub1} sub2={Clientdata[0].sub2} sub3={Clientdata[0].sub3} />
<Clientcard industry={Clientdata[1].industry} sub1={Clientdata[1].sub1} sub2={Clientdata[1].sub2} sub3={Clientdata[1].sub3}/>
<Clientcard industry={Clientdata[2].industry} sub1={Clientdata[2].sub1} sub2={Clientdata[2].sub2} sub3={Clientdata[2].sub3}/>
</div>
<div class='client-card-align'>
<Clientcard industry={Clientdata[3].industry} sub1={Clientdata[3].sub1} sub2={Clientdata[3].sub2} sub3={Clientdata[3].sub3}/>
<Clientcard industry={Clientdata[4].industry} sub1={Clientdata[4].sub1} sub2={Clientdata[4].sub2} sub3={Clientdata[4].sub3}/>
<Clientcard industry={Clientdata[5].industry} sub1={Clientdata[5].sub1} sub2={Clientdata[5].sub2} sub3={Clientdata[5].sub3}/>
</div>
<div class='client-card-align'>
<Clientcard industry={Clientdata[6].industry} sub1={Clientdata[6].sub1} sub2={Clientdata[6].sub2} sub3={Clientdata[6].sub3}/>
<Clientcard industry={Clientdata[7].industry} sub1={Clientdata[7].sub1} sub2={Clientdata[7].sub2} sub3={Clientdata[7].sub3}/>
<Clientcard industry={Clientdata[8].industry} sub1={Clientdata[8].sub1} sub2={Clientdata[8].sub2} sub3={Clientdata[8].sub3}/>
</div>
<div class='client-card-align'>
<Clientcard industry={Clientdata[9].industry} sub1={Clientdata[9].sub1} sub2={Clientdata[9].sub2} sub3={Clientdata[9].sub3}/>
<Clientcard industry={Clientdata[10].industry} sub1={Clientdata[10].sub1} sub2={Clientdata[10].sub2} sub3={Clientdata[10].sub3}/>
<Clientcard industry={Clientdata[11].industry} sub1={Clientdata[11].sub1} sub2={Clientdata[11].sub2} sub3={Clientdata[11].sub3}/>
</div>
{/* <div class='client-card-align'>
<Clientcard industry={Clientdata[12].industry} sub1={Clientdata[12].sub1} sub2={Clientdata[12].sub2} sub3={Clientdata[12].sub3}/>
<Clientcard industry={Clientdata[13].industry} sub1={Clientdata[13].sub1} sub2={Clientdata[13].sub2} sub3={Clientdata[13].sub3}/>
</div> */}
</div>
<div class='values-section'>
 
    <h1 class='value-head'>Partner with us </h1>
    <p class='value-para'>At Mohindro Arora & Co., we understand the unique challenges and opportunities within each industry. Our dedicated team of professionals provides customized solutions that cater to the specific needs of our clients, ensuring their success and growth.</p>
    <p class='client-highlight-para'>Let us help you achieve your financial and business goals with our expert services and personalized approach. </p>
    
</div>
<ScrollToTop />
<Footer />
        </>
    );
}

export default Client ;