import Vision from'./images/Group-2.svg';
import Mission from'./images/Group.svg';
import Values from'./images/Group-1.svg';
const Vdata=[
    {
       img:Vision,
       heading:"Our Vision",
       para:"To be the most respected and trusted professional organization devoted to delivering excellence."
    },
    {
        img:Mission,
        heading:"Our Mission",
        para:"To provide unparalleled financial services with a commitment to quality, accuracy, and client-centric solutions."
     },
     {
        img:Values,
        heading:"Our Values",
        para:"Integrity, Excellence, Client-Centricty, Innovation Collaboraton and Professional Development."
     },
]

export default Vdata;