import React from 'react';
import './style.css';

function Card(props) {
    return(
    <>

       <div class='card1'>
         <img src={props.img} alt='accountant'className='account-logo'/>
         <h2 class='card-heading'>{props.heading}</h2>
         <p class='card-text'>{props.para}</p>
       </div>

   </>
 );
}


export default  Card;