import React from "react";
import './style.css';

function Values(props) {
    return(
        <>
        <div class='Card2'>
        <img src={props.img} alt='values' class='mission-img'/>
        <p class='mission-heading'>{props.heading}</p>
        <p class='mission-text'>{props.para}</p>
       

        </div>
        </>
    );
}


export default Values;