
import Accounting from'./images/accounting 1.png';
import Auditing from'./images/auditing 1.png';
import Duedeligence from'./images/due deligence 1.png';
import Finance from'./images/finance 1.png';
import Incometax from'./images/Group 1.png';
import Gst from'./images/gst 1.png';
import Merger from'./images/merger 1.png';
import Startup from'./images/startup 1.png';
import Companylaw from'./images/Vector.png';

const Sdata = [
    {
        img:Accounting,
        heading:"Accounting",
        para:"Accurate and compliant accounting services to help you maintain precise financial records and make informed decisions."
    },
    {
        img:Auditing,
        heading:"Auditing & Assurance",
        para:"Enhancing the credibility of your financial statements with our thorough auditing and assurance services."
    },
    {
        img:Incometax,
        heading:"Income Tax",
        para:"Expert guidance on income tax planning, compliance, and filing to navigate the complexities of tax laws."
    },
    {
        img:Gst,
        heading:"Goods & Services Tax (GST)",
        para:"Complete GST services, from registration to compliance and advisory, ensuring smooth adherence to GST regulations."
    },
    {
        img:Startup,
        heading:"Startup Services",
        para:"Supporting startups with business planning, financial modeling, and compliance to turn innovative ideas into successful businesses."
    },
    {
        img:Companylaw,
        heading:"Company Law Matters",
        para:"Ensure legal compliance, corporate governance, company formation, M&A support, and dispute resolution for businesses."
    },
    {
        img:Merger,
        heading:"Mergers & Acquisitions",
        para:"Providing advisory, due diligence, and transaction support for mergers, acquisitions, and corporate restructuring."
    },
    {
        img:Duedeligence,
        heading:"Due Diligence Services",
        para:"Thorough due diligence to assess financial, operational, and strategic aspects for informed decision-making."
    },
    {
        img:Finance,
        heading:"Finance",
        para:"Financial planning, analysis, and advisory services to help you achieve your financial goals."
    },
]

export default Sdata;