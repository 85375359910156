import React from "react";
import './style.css';

function Director(props) {
    return(
        <>
    <div class='dir-align'>
     <img src={props.img} alt='arora' class='dir-image'/>
     <p class='dir-name'>{props.name}</p>
     <p class='dir-qua'>{props.profile}</p>
    </div>
        </>
    );
}

export default Director ;